import React from "react"

const ContactForm = () => (
  <form
    onSubmit={event => {
      window.location.replace("/thankyou?SameSite=Lax")
    }}
    name="contact_form"
    id="contact_form"
    data-netlify="true"
    method="POST"
    action="/thankyou"
    class="grid grid-cols-4 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4"
  >
    <input type="hidden" name="form-name" value="contact_form" />

    <div class="col-span-2 xs:col-span-4 sm:col-span-4">
      <label
        for="first_name"
        class="block text-sm font-medium leading-5 text-gray-500"
      >
        First name
      </label>
      <div class="mt-1 flex rounded-md shadow-sm">
        <div class="relative flex-grow focus-within:z-10">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
            </svg>
          </div>
          <input
            id="first_name"
            name="first_name"
            class="form-input block w-full rounded-none rounded-l-md pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            placeholder="John"
          />
        </div>
      </div>
    </div>
    <div class="col-span-2 xs:col-span-4 sm:col-span-4">
      <label
        for="last_name"
        class="block text-sm font-medium leading-5 text-gray-500"
      >
        Last name
      </label>
      <div class="mt-1 flex rounded-md shadow-sm">
        <div class="relative flex-grow focus-within:z-10">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
            </svg>
          </div>
          <input
            id="last_name"
            name="last_name"
            class="form-input block w-full rounded-none rounded-l-md pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            placeholder="Doe"
          />
        </div>
      </div>
    </div>
    <div class="col-span-2 xs:col-span-4 sm:col-span-4">
      <div>
        <label
          for="email"
          class="block text-sm font-medium leading-5 text-gray-700"
        >
          Email
        </label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
          </div>
          <input
            id="email"
            name="email"
            class="form-input block w-full pl-10 sm:text-sm sm:leading-5"
            placeholder="you@example.com"
          />
        </div>
      </div>
    </div>
    <div class="col-span-2 xs:col-span-4 sm:col-span-4">
      <label
        for="phone_number"
        class="block text-sm font-medium leading-5 text-gray-500"
      >
        Phone
      </label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="phone-alt"
            class="h-5 w-5 text-gray-400 "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"
            ></path>
          </svg>
        </div>
        <input
          name="phone_number"
          id="phone_number"
          class="form-input block w-full pl-10 sm:text-sm sm:leading-5"
          placeholder="555-123-5678"
          type="tel"
        />
      </div>
    </div>
    <div class="col-span-4">
      <label
        for="message"
        class="block text-sm leading-5 font-medium text-gray-700"
      >
        Message
      </label>
      <div class="rounded-md">
        <textarea
          id="message"
          name="message"
          type="text"
          rows="5"
          cols="150"
          class="resize form-textarea mt-1 block transition duration-150 ease-in-out sm:text-sm sm:leading-5 max-w-full"
          placeholder="I'm interested in getting private instruction. Can I get a quote?"
        ></textarea>
      </div>
    </div>
    <div class="col-span-1 col-start-4">
      <span class="rounded-md shadow-sm">
        <input
          type="submit"
          value="Submit"
          class="w-full py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-blue-600 transition duration-150 ease-in-out"
        />
      </span>
    </div>
  </form>
)
export default ContactForm
