import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

import backDropImage from "../images/backDropImage.jpg"

const ContactUs = () => {
  return (
    <Layout>
      <SEO title="Contact us | Savage Outdoor & Defense" />
      <div class="relative overflow-hidden max-w-full mx-auto grid grid-cols-5 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1">
        <div class="absolute bottom-0 z-0">
          <img
            class="xs:max-w-5xl sm:max-w-4xl md:max-w-4xl lg:max-w-2xl xl:max-w-3xl object-fit object-center"
            src={backDropImage}
            alt="License to Carry (LTC) course in Bryan, College Station Texas. Brazos County"
          />
        </div>
        <div class="bg-black bg-opacity-50 sm:px-6 col-span-2 xs:col-span-1 sm:col-span-1 px-8 py-6 pr-12 z-10">
          <div class="bg-white shadow-lg rounded p-4 max-w-md mx-auto">
            <h2 class="text-3xl mb-6 font-medium leading-6 text-gray-900">
              Get in Touch
            </h2>
            <dl class="text-base leading-6 text-gray-500">
              <div class="mt-3">
                <dt class="sr-only">Phone number</dt>
                <dd class="flex">
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    ></path>
                  </svg>
                  <span class="ml-3">
                    <a
                      href="tel:979-429-3414"
                      class="text-gray-500 font-medium hover:text-red-500"
                    >
                      979-429-3414
                    </a>
                  </span>
                </dd>
              </div>
              <div class="mt-3">
                <dt class="sr-only">Email</dt>
                <dd class="flex">
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    ></path>
                  </svg>
                  <span class="ml-3">
                    <a
                      href="mailto:support@savageoutdoordefense.com"
                      class="text-gray-500 font-medium hover:text-red-500"
                    >
                      support@savageoutdoordefense.com
                    </a>
                  </span>
                </dd>
              </div>
            </dl>
            <div class="grid grid-cols-2 grid-flow-row">
              <div class="mt-4">
                <h3 class="text-lg font-medium leading-6 text-gray-500 mb-1">
                  Hours
                </h3>
                <ul class="text-gray-500">
                  <li>
                    <span class="font-semibold text-right">
                      {" "}
                      By appointment only
                    </span>
                  </li>
                </ul>
              </div>
              <div class="mt-4">
                <dt class="sr-only">Location</dt>
                <h2 class="text-lg font-medium leading-6 text-gray-500">
                  Location
                </h2>
                <dd>
                  <p class="text-gray-500">4036 FM974, Bryan, TX, 77808</p>
                </dd>
                <div class="mt-3 text-gray-500">
                  {" "}
                  <h3 class="text-lg font-medium leading-6 text-gray-500 mb-1">
                    Social
                  </h3>
                  <div class="inline">
                    <a href="https://instagram.com/savage.outdoor.defense?igshid=195ehpl2eiuzb">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="instagram"
                        class="svg-inline--fa fa-instagram fa-w-14 my-1 mr-1 fa-lg text-red-700"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                  <div class="inline">
                    <a href="https://www.youtube.com/channel/UCnQ9Q8wu8lRKO2QMbdLQk0g">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="youtube-square"
                        class="svg-inline--fa fa-youtube-square fa-w-14 m-1 fa-lg text-red-700"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                  <div class="inline">
                    <a href="https://www.reddit.com/u/Savage_Out_And_Def?utm_medium=android_app&amp;utm_source=share">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="reddit-square"
                        class="svg-inline--fa fa-reddit-square fa-w-14 m-1 fa-lg text-red-700"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M283.2 345.5c2.7 2.7 2.7 6.8 0 9.2-24.5 24.5-93.8 24.6-118.4 0-2.7-2.4-2.7-6.5 0-9.2 2.4-2.4 6.5-2.4 8.9 0 18.7 19.2 81 19.6 100.5 0 2.4-2.3 6.6-2.3 9 0zm-91.3-53.8c0-14.9-11.9-26.8-26.5-26.8-14.9 0-26.8 11.9-26.8 26.8 0 14.6 11.9 26.5 26.8 26.5 14.6 0 26.5-11.9 26.5-26.5zm90.7-26.8c-14.6 0-26.5 11.9-26.5 26.8 0 14.6 11.9 26.5 26.5 26.5 14.9 0 26.8-11.9 26.8-26.5 0-14.9-11.9-26.8-26.8-26.8zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-99.7 140.6c-10.1 0-19 4.2-25.6 10.7-24.1-16.7-56.5-27.4-92.5-28.6l18.7-84.2 59.5 13.4c0 14.6 11.9 26.5 26.5 26.5 14.9 0 26.8-12.2 26.8-26.8 0-14.6-11.9-26.8-26.8-26.8-10.4 0-19.3 6.2-23.8 14.9l-65.7-14.6c-3.3-.9-6.5 1.5-7.4 4.8l-20.5 92.8c-35.7 1.5-67.8 12.2-91.9 28.9-6.5-6.8-15.8-11-25.9-11-37.5 0-49.8 50.4-15.5 67.5-1.2 5.4-1.8 11-1.8 16.7 0 56.5 63.7 102.3 141.9 102.3 78.5 0 142.2-45.8 142.2-102.3 0-5.7-.6-11.6-2.1-17 33.6-17.2 21.2-67.2-16.1-67.2z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                  <div class="inline">
                    <a href="https://twitter.com/SavageOutAndDef">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="twitter-square"
                        class="svg-inline--fa fa-twitter-square fa-w-14 m-1 fa-lg text-red-700"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                  <div class="inline">
                    <a href="fb.me/savageoutdoordefense">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="facebook-square"
                        class="svg-inline--fa fa-facebook-square fa-w-14 m-1 fa-lg text-red-700"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13717.093195594682!2d-96.3657359!3d30.7388238!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3634c94d2c9c1221!2sSavage%20Outdoor%20%26%20Defense!5e0!3m2!1sen!2sus!4v1635005412731!5m2!1sen!2sus"
              class="w-full max-w-full mt-4 h-64"
              frameborder="0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
        <div class="bg-white sm:px-6 col-span-3 py-12 px-8 pl-12 xs:row-start-1 sm:row-start-1 md:row-start-1 z-10">
          <div class="max-w-full mx-auto lg:max-w-none">
            <h2 class="text-3xl mb-6 font-medium leading-6 text-gray-900">
              Request Information
            </h2>
            <p class="text-gray-900 max-w-full mb-3">
              Howdy, and thanks for checking out our site! If you don’t see what
              you’re looking for, you’d like to order merchandise, you'd like to
              book a range session, or you’d like to schedule a private
              instruction session, please fill out the contact form and shoot us
              a message!
            </p>
            <ContactForm />
          </div>
        </div>
      </div>
      <div
        id="confirmation_message"
        class="hidden fixed bottom-1/2 w-64 mx-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:hidden sm:flex sm:items-center sm:justify-center"
      >
        <div
          class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl sm:max-w-sm sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <svg
                class="h-6 w-6 text-green-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Message sent!
              </h3>
              <div class="mt-2">
                <p class="text-sm leading-5 text-gray-500">
                  Your message is important to us! We will get back with you
                  within 1 business day
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUs
